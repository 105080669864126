body {
    margin: 0;
    font-family: "Calibre", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant-numeric: tabular-nums;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body svg {
    pointer-events: none;
}

@font-face {
    font-family: 'Calibre';
    font-weight: 300;
    src: url("/fonts/Calibre/calibre-light.woff2") format("woff"),
    url("/fonts/Calibre/Calibre-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Calibre';
    font-weight: 400;
    src: url("/fonts/Calibre/calibre-regular.woff2") format("woff"),
    url("/fonts/Calibre/Calibre-Regular.ttf") format("truetype");
}
@font-face {
    font-family: 'Calibre';
    font-weight: 500;
    src: url("/fonts/Calibre/calibre-medium.woff2") format("woff"),
    url("/fonts/Calibre/Calibre-Medium.ttf") format("truetype");
}
@font-face {
    font-family: 'Calibre';
    font-weight: 600;
    src: url("/fonts/Calibre/calibre-medium.woff2") format("woff"),
    url("/fonts/Calibre/Calibre-Medium.ttf") format("truetype"); /* Calibre semibold is truly ugly. Since weight="bold" in CSS defaults to 600, we're duplicating it here */
}
@font-face {
    font-family: 'Calibre';
    font-weight: 700;
    src: url("/fonts/Calibre/calibre-semibold.woff2") format("woff"),
    url("/fonts/Calibre/Calibre-Semibold.ttf") format("truetype");
}
@font-face {
    font-family: 'Calibre';
    font-weight: 800;
    src: url("/fonts/Calibre/calibre-bold.woff2") format("woff"),
    url("/fonts/Calibre/Calibre-Bold.ttf") format("truetype");
}

/* scroll bar styling */
::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
}
::-webkit-scrollbar-thumb {
    background-color: #efefef;
    border-radius: 10px;
}

/* Print Settings */
@media all {
    .page-break {
        display: none;
    }
}

@media print {
    html, body, #__next > div > div {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        position: relative;
        background: none;
    }

    h1, h2, h3, p, span {
        page-break-inside: avoid;
        -webkit-region-break-inside: avoid;
        position: relative;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        break-before: auto;
    }

    #__next > div {
        height: 100% !important;
    }

    .badge-text { /* in dark mode, grommet doesn't resolve badge color in print correctly */
        color: black;
    }
}

@page {
    size: auto;
    margin: 8mm;
}

/* remove grommet close menu button */
button[aria-label="Close Menu"] {
    display: none;
}

/* overriding grommet stylings for sticky top bar */
.grommet-wrapper {
    height: initial !important; /* grommet is forcing this div to be height 100vh, and it's not allowing us to get y scroll position */
    overflow: initial !important;
    max-width: 100%; /* grommet likes to not take into account of the scroll bar width and grows beyond 100% of the window */
}